<div *ngIf="loaded===false">
  <app-loading></app-loading>
</div>
<div *ngIf="modal">
  <app-refund-modal-not-found [texto]=texto [cultureKentico]=cultureKentico [modalEmail]=modalEmail [modalPnr]=searchPnr [emailOnRedirect]=searchEmail ></app-refund-modal-not-found>
</div>
<div #lista>
  <div *ngIf="loaded && modal===false && errors===false" class="row justify-content-md-center">
    <div *ngIf="changeConditions">
      <app-refund-modal-not-found [texto]=changeConditionsText
                                  [cultureKentico]=cultureKentico></app-refund-modal-not-found>
    </div>
    <div *ngIf="historics">
      <div class="list-container">
        <h2 class="list-title">{{ cmsListSection.list_historic_title }} {{search.pnr}}</h2>
        <p class="refund_complete">
          {{ cmsListSection.list_historic_text }}
        </p>
      </div>
    </div>
    <!-- Datos de devolución por pasajero por segmento -->
    <div *ngIf="historics === false" class="mb-5">
      <form [formGroup]="devolucionesCheckbox">
        <div class="col-12 pb-4 pt-3 dev-results">

          <h4 *ngIf="devolucionCompleta && !reservaService.voucherRefund" class="d-inline color-blue-js font-lato-light"><img class="icon-form"
            src="https://{{baseUrl}}/assets/passenger.png"
            alt=""> {{ cmsListSection.list_complete_title }}
          </h4>
          <!-- Texto "Selecciona los pasajeros" -->
          <h4 *ngIf="!devolucionCompleta && !reservaService.voucherRefund" class="d-inline color-blue-js font-lato-light"><img class="icon-form"
            src="https://{{baseUrl}}/assets/passenger.png"
            alt=""> {{ cmsListSection.list_new_title }}
          </h4>
          <h4 *ngIf="devolucionCompleta && reservaService.voucherRefund" class="d-inline color-blue-js font-lato-light"><img class="icon-form"
            src="https://{{baseUrl}}/assets/passenger.png"
            alt=""> {{ cmsListSection.list_complete_title_voucher }}

          </h4>
          <h4 *ngIf="!devolucionCompleta && reservaService.voucherRefund" class="d-inline color-blue-js font-lato-light"><img class="icon-form"
            src="https://{{baseUrl}}/assets/passenger.png"
            alt="">
            <span *ngFor="let item of combinedArreglo" class="d-inline color-blue-js font-lato-light">
              <span *ngIf="!isAnArray(item)" class="color-blue-js font-lato">{{item}}</span>
              <span *ngIf="isAnArray(item)" class="color-blue-js font-lato-bold">{{item}}</span>
            </span>
          </h4>


          <!-- Texto indicaciones para voucher -->
          <p *ngIf="reservaService.voucherRefund" class="color-blue-js" style="padding-left: 44px;">{{ cmsListSection.voucher_refund_indications }}</p>
          <div *ngFor="let i of reservas; let indice = index" class="passenger">
            <div class="{{overlay[indice] === true ? 'overlay' : 'none'}}"></div>
            <div class="row" >
              <div (click)="toggleCheckboxes(indice)" class="col-sm-8 col-md-6 segment-title">
                <h5 class="font-lato-bold">
                  <i [ngClass]="{'color-light-blue-js': true, 'far': !everyPaxIsSelected(indice), 'fas': everyPaxIsSelected(indice),  'fa-check-square': somePaxIsSelected(indice), 'fa-square': !somePaxIsSelected(indice)}" style="padding-right: 10px;"></i>
                  <i class="fas fa-plane-departure" style="padding-right: 5px"></i>  {{i.DepartureName}}&nbsp;<span class="arrow" style="color: #b92234">→
              </span>
              <i class="fas fa-plane-arrival" style="padding-right: 5px"></i>{{i.ArrivalName}}
                </h5>
              </div>
              <div class="col" >
                <p class="date-form " style="font-weight: bold;"> {{getDate(i.Pax[0].STD)}}
                </p>
              </div>
            </div>
            <div *ngIf="i.legs.length > 0" class="row" style="padding-left: 15px">
              <div *ngFor="let leg of legsArray[indice].slice(0, legsArray[indice].length - 1)" class="color-blue-js font-lato">
                *1 <span *ngIf="i.flightType === 4; else other_content_parada">{{ cmsListSection.connection_text || "Conexi&oacute;n" }}</span> 
                <ng-template #other_content_parada>{{ cmsListSection.parada_text || "parada" }}</ng-template>
                - {{ getStation(i, leg.designator.destination) }} - {{ cmsListSection.tiempo_de_espera_text || "Tiempo estimado de espera" }}: {{ leg.tiempoDeEspera }}
              </div>
            </div>

            <div *ngIf="reservas.length>0">
              <div>
                <div *ngFor="let j of i.Pax; let indice2 = index" [formArrayName]="indice">
                  <div [formGroupName]="indice2" class="row row-passenger">
                    <div class="col-xl-7 col-lg-7 col-12 mt-3">
                      <div class="row">
                        <div class="col-xl-8 col-lg-7 col-6">
                          <div class="custom-control custom-checkbox">
                            <!-- Este checkbox es el de cada pasajero -->
                            <input type="checkbox" id="{{j.PassengerID+j.FlightNumber}}" formControlName="check" [disabled]="true"
                                   class="custom-control-input"
                                   required (change)="handleCheckbox($event.target)"/>
                            <label for="{{j.PassengerID+j.FlightNumber}}" class="custom-control-label">{{
                                cmsListSection.item_pax_text }}
                              {{j.PaxName}}</label>
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-5 col-6"> <!-- cambiar list_Dev por list_voucher si es voucher. Lo demás se mantiene si no es voucher desaparece si sí-->
                          <p class="refundTotal">{{ this.reservaService.voucherRefund ? cmsListSection.list_voucher : cmsListSection.list_dev }}
                            <span *ngIf="j.IndRefundType === 'NO'"> {{
                                cmsListSection.item_none_type_text }}</span>
                            <span *ngIf="j.IndRefundType === 'TOTAL' && reservaService.voucherRefund === false"> {{ cmsListSection.item_full_type_text }}</span>
                            <span
                              *ngIf="j.IndRefundType === 'PARCIAL' && reservaService.voucherRefund === false"> {{ cmsListSection.item_partial_type_text }}</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-5 col-lg-5 col-12 mt-3">
                      <div class="row">
                        <div class="col-xl-7 col-lg-7 col-md-9 col-sm-8 col-5 px-lg-1">
                          <p class="dev-amount-text font-lato-black">{{ cmsListSection.item_amount_text }}</p>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-3 col-sm-4 col-7 px-lg-1">
                          <div class="dev-amount font-lato-bold">{{currencyCode}}$ {{j.RefundAmount |
                              currency:currencyCode:''}}</div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!devolucionCompleta" class="row total">
            <div class="ml-auto col-xl-5 col-lg-5 col-12 mt-3">
              <div class="row">
                <div class="col-xl-7 col-lg-7 col-md-9 col-sm-8 col-5 px-lg-1">
                  <p class="dev-amount-text font-lato-black">{{cmsListSection.list_additional}}</p>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-3 col-sm-4 col-7 px-lg-1">
                  <div class="dev-amount font-lato-bold">{{currencyCode}} $ {{additionals | currency:currencyCode:''}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-7 col-lg-7 col-md-9 col-sm-8 col-5 px-lg-1">
                  <p *ngIf="!reservaService.voucherRefund" class="dev-amount-text font-lato-black">{{cmsListSection.list_total}}</p>
                  <p *ngIf="reservaService.voucherRefund" class="dev-amount-text font-lato-black">{{cmsListSection.list_total_voucher}}</p>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-3 col-sm-4 col-7 px-lg-1">
                  <div class="dev-amount font-lato-bold"> {{currencyCode}} $ {{total + additionals | currency:currencyCode:''}} </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="reservaService.hasPercentGC == 'DEVGC1'" class="results-small-text font-lato-light" [innerHTML]="cmsListSection.msg_GC_adicional"></div>
          <div *ngIf="reservaService.hasPercentGC == 'DEVGC2'" class="results-small-text font-lato-light" [innerHTML]="cmsListSection.msg_GC_adicional2"></div>
          <div *ngIf="reservaService.hasPercentGC == 'DEVGC3'" class="results-small-text font-lato-light" [innerHTML]="cmsListSection.msg_GC_adicional3"></div>
          <p *ngIf="!reservaService.voucherRefund" class="results-small-text font-lato-light ">
            {{cmsListSection.list_footnote}}</p>  <!-- Si es voucher esto desaparece -->

          <p *ngIf="!reservaService.voucherRefund" class="results-small-text font-lato-light">{{cmsListSection.list_footnote_tax}} <!-- Si es voucher esto desaparece -->
          </p>
          <p *ngIf="devolucionCompleta" class="refund_complete">
            {{cmsListSection.list_complete_footnote}}
          </p>
          <div *ngIf="submitted && validCheckbox === false" style="color: red; margin-top: 1px;">
            {{cmsListSection.list_required_validation_text}}
          </div>

        </div>
      </form>
    </div>
    <div *ngIf="(displayProcess === true && loaded) && !devolucionCompleta">
      <!-- Última forma, la de los datos bancarios -->
      <form [formGroup]="lastForm" (ngSubmit)="onSubmit()">
        <div #formdiv class="col-12 mb-5 pt-3 account dev-results">
          <app-form *ngIf="reservaService.showAccountForm" [getPayments]=getPayments
                    [errores_form]="errores_form"
                    [lastPayment]="lastPayment"
                    [currencyCode]= "currencyCode"
                    (payments_submitted)="submitHandler($event)" [cmsListSection]=cmsListSection
                    (countryChild)="changeCountry($event)"
                    (changeError)="changeError()"
                    ></app-form>

          <div *ngIf="!devolucionCompleta" class="row justify-content-md-center  mb-5 pb-3">
            <div class="col col-lg-12">
              <div class="form-group custom-control custom-checkbox">
                <input type="checkbox" formControlName="Accept" class="custom-control-input" id="Accept" (change)="changePoliticies()">
                <label *ngIf="refundType === 'TOTAL' && !reservaService.voucherRefund" for="Accept"
                       class="custom-control-label color-red-js font-weight-bold text-justify">

                  {{cmsListSection.form_full_conditions_text_start}}
                  <a href="{{cmsListSection.pdf_conditions[0].url}}" target="_blank">
                    <span class="color-blue-js"> {{cmsListSection.form_full_conditions_text_link}}</span>
                  </a>{{cmsListSection.form_full_conditions_text_end}}
                </label>



                 <label  *ngIf="reservaService.voucherRefund" for="Accept"
                       class="custom-control-label color-red-js font-weight-bold text-justify">
                  <span *ngFor="let item of this.combinedArray">
                    <span *ngIf="!isAnArray(item)">{{item}}</span>
                    <a *ngIf="isAnArray(item)" href="{{generateURL(item[1])}}" target="_blank">
                      <span class="color-blue-js">{{item[0]}}</span>
                    </a>
                  </span>
                </label>



                <label *ngIf="refundType === 'PARCIAL' && !reservaService.voucherRefund" for="Accept"
                       class="custom-control-label color-red-js font-weight-bold text-justify">
                  {{cmsListSection.form_partial_conditions_text_start}}
                  <a href="{{cmsListSection.pdf_conditions[0].url}}" target="_blank">
                    <span class="color-blue-js"> {{cmsListSection.form_partial_conditions_text_link}}</span>
                  </a>
                  {{cmsListSection.form_partial_conditions_text_end}}
                </label>
              <!--   <label *ngIf="reservaService.voucherRefund" for="Accept"
                        class="custom-control-label color-red-js font-weight-bold text-justify">
                  {{cmsListSection.form_voucher_conditions_text_start}}
                  <a href="{{cmsListSection.pdf_conditions[0].url}}" target="_blank">
                    <span class="color-blue-js"> {{cmsListSection.form_voucher_conditions_text_link}}</span>
                  </a>
                  {{cmsListSection.form_voucher_conditions_text_end}}
                </label> -->

                <div *ngIf="submitted && L.Accept.errors" class="invalid-feedback" style="color:red; margin-top: 1px;">
                  <div *ngIf="L.Accept.errors.required">{{cmsListSection.list_required_text}}</div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!devolucionCompleta" class="col-12 pb-5">
            <div class="form-group text-right">
              <div class="form-captcha-container">
                <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (expire)="handleExpire()"
                                (load)="handleLoad()" (success)="handleSuccess($event)" [size]="size" [hl]="lang"
                                [theme]="theme"
                                [type]="type" formControlName="recaptcha" class="form-recaptcha"
                                [ngClass]="{ 'is-invalid': submitted && L.recaptcha.errors }">
                </ngx-recaptcha2>
              </div>
              <div *ngIf="submitted && L.recaptcha.errors" class="invalid-recaptcha"
                   style="color:red; margin-top: 1px;">
                <div *ngIf="L.recaptcha.errors.required">{{cmsListSection.recaptcha_validation_text}}</div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-12">
          <button type="submit"  [disabled]="!politicies || !captchaSuccess"  name="submitRefunds"
                  class="float-right" [ngClass]="!(politicies && captchaSuccess) ? 'disabled-btn' : 'primary-btn'">{{cmsListSection.form_send_button_text}}</button>
        </div>
      </form>
    </div>

  </div>
</div>
